.main-content-dashboard {
    padding: 75px 16px;
    height: 100%;
}
.home-page-dashboard .navbar {
    justify-content: left;
}
.navbar-user-info img {
    width: 30px;
    border-radius: 100%;
    margin-right: 10px;
}
.reset-button{
    padding: 0px 10px 10px 10px;
}