.main-content-dashboard {
    padding-top: 75px;
    height: 100%;
}
.home-page-dashboard .navbar {
    justify-content: left;
}
.navbar-user-info img {
    width: 30px;
    border-radius: 100%;
    margin-right: 10px;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: rgba(255, 255, 255, 0.87) !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
    color: rgba(255, 255, 255, 0.54) !important;
}
.MuiDialog-paperFullWidth {
    height: auto;
}
.MuiDialogContent-root {
    max-height: 100% !important
}
.MuiTableContainer-root {
    height: 100%;
}
.dashboard-table .MuiTableCell-body {
    padding: 0 16px !important;
}
@media only screen and (min-width:641px) and (max-width:825px){
    .main-content-dashboard {
       
        height: 130vh;
    }
  
  }