#login {
    margin: 0;
    padding: 0;
    background-color: #17a2b8;
    height: 100vh;
}
#login .container #login-row #login-column #login-box {
    margin-top: 120px;
    max-width: 600px;
    height: auto;
    border: 1px solid #9C9C9C;
    background-color: #EAEAEA;
}
#login .container #login-row #login-column #login-box #login-form {
    padding: 20px;
}
#login .container #login-row #login-column #login-box #login-form #register-link {
    margin-top: -85px;
}
#login .container #login-row #login-column #login-box .form-group .float-right{
    cursor: pointer;
}
@media only screen and (min-width:641px) and (max-width:825px){
    #login .container #login-row #login-column #login-box {
        margin-top: 48px;
    }
  
  }