body{
    overflow: auto;
}
.container-fluid {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 130vh;
    padding: 0px !important;
    margin: auto !important;
}
.main-content {
  padding-top: 75px;
  height: 100%;
}
.main-content .main-content-left-map {
  height: calc(100vh - 100px);
  overflow: hidden;
  overflow-y: auto;
}
.main-content-right {
  /* height: calc(100vh - 175px) !important; */
  height: 74vh !important;
  overflow: hidden !important;
  overflow-y: auto !important;
}
.mapview{
  height: 74vh;
  width: 49vw;
}
.home-page .navbar {
  justify-content: left;
}
.home-page .navbar .form-control {
  min-width: 300px;
  min-height: 44px;
  border-radius: 0;
  border: 1px solid #425eea;
}
.home-page .navbar .form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}
.home-page .navbar .form-inline button {
  background-color: #425eea;
  color: #fff;
  min-height: 44px;
  border-radius: 0;
  margin-left: -2px;
}
.home-page .navbar .form-inline button:focus {
  outline: none !important;
  box-shadow: none !important;
}
.main-content-div{
  padding: 0px 8px;
}
.home-page
  .navbar
  .form-inline
  .btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  background-color: #425eea !important;
  border-color: #425eea !important;
}
.main-content-right .col-md-6 {
  margin-bottom: 20px;
}
.gm-style-iw-d th {
  padding: 10px;
}
.gm-style-iw.gm-style-iw-c {
  max-height: 400px !important;
}
label#demo-simple-select-label {
  overflow: hidden;
  width: 86%;
  height: 17px;
}
button.refreshMap {
  z-index: 600;
  position: absolute;
  top: 12px;
  left: 50px;
}

button.searchButton {
  margin-bottom: 10px;
  margin-left: 10px;
}

.MuiFormControl-root {
  width: 100%;
}
.bold {
  font-weight: bold;
}
h6.no-data {
  display: block;
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  margin-top: 24px;
}
.active {
  background: #3f51b50f;
  box-shadow: 10px 10px 13px lightgrey;
  height: 100%;
  cursor: pointer;
}
.inactive{
  cursor: pointer;
}
.marker-popup{
  max-width: 350px;
}
.main-content-item{
  width: 100%;
}
.leaflet-popup-content p {
  margin: 5px 0 !important;
  font-size: 12px !important;
}
.mb-10 {
  margin-bottom: 15px;
  padding-right: 0px !important;
}
.card {
  width: 100%;
  height: 300px;
  position : relative;
}
.card-title {
  font-size: 0.8rem;
  margin-bottom: 0.2rem !important;
}
.popup-extra-data{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
.address{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.card-subtitle,
.card-text {
  font-size: 12px;
  margin-bottom: 0.2rem;
}
.card-body {
  padding: 0.35rem 1.25rem;
}

.intrinsic-item {
  height: 155px;
  width: 100%;
}
.top-bar {
  margin: 0 20px;
}

.leaflet-control-container {
  position: static !important;
  height: calc(100vh - 220px) !important;
}

a.file-preview {
  background: #3f51b5;
  color: #fff !important;
  border-radius: 2px;
  padding: 5px 10px;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
}
a.file-preview:hover {
  box-shadow: 5px 3px 4px lightgrey;
}
.pb-10 {
  padding-bottom: 10px;
}
.leaflet-container a.leaflet-popup-close-button {
  right: 4px !important;
  top: 2px !important;
  font-size: 22px !important;
}
.mapbutton{
  z-index: 100;
}
.homeone{
  height: 100vh!important;
}

.leaflet-popup {
  width: 300px;
  margin-bottom: -35px !important;
}
.search {
  float: right;
  width: 50%;
}
.input_field {
  margin: auto;
}
.card_data {
  height: 76vh !important;
}
.popup_mes{
 margin-bottom: 30px !important;
}
.popup_default{
  margin-bottom: -35px !important;
}
.extra {
  display: flex !important;
  justify-content: space-around;
}
.numbers {
  position: absolute;
  background-color: green;
  color: white;
  width: 25px;
  border-radius: 13px;
  height: 25px;
  text-align: center;
}
.conditional_icon {
  display: none;
}
.model_data {
  margin-bottom: 5px;
  width: 100%;
  font-size: 14px;
  max-width: 100%;
  word-wrap: break-word;
}
.model-card-box-img {
  height: 155px !important;
}
/* .model-card{
  padding: 15px;
} */
.model-card-box {
  padding: 16px;
}
.model-item-detail{
  padding: 5px
}
.extranav {
  display: flex !important;
  justify-content: space-between !important;
}
.left-nav {
  display: flex;
}

.pd-5 {
  padding: 5px !important;
}
.mob_dis {
  display: none;
}
@media (min-width: 300px) and (max-width: 600px) {
  .mapview{
    width: 85vw !important;
    margin: auto;
  }
}

@media (min-width: 300px) and (max-width: 480px) {
  .extranav {
    display: none !important;
  }
  button.searchButton {
    margin-bottom: 5px;
    margin-left: 0px;
    padding: 8px;
  }
  .map-hide{
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .container-fluid {
    width: 100%;
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    
  }
  .container {
    width: 100% !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .mob_dis {
    display: block !important;
    width: 100% !important;
    height: 70px;
    background-color: #f8f9fa !important;
    /* margin: 0px 0px 0px -20px; */
  }
  .main-content {
    padding-top: 6px;
  }
  .date_picker_res > .MuiFormLabel-root {
    font-size: 14px;
  }
  .thirdcol_second {
    font-size: 14px !important;
  }
 
  .mob_dis_icon {
    float: right;
    margin: 17px 17px 0px 0px;
  }
  .card {
    display: flex !important;
    flex-direction: column !important;
  }
 
  
  .unit_tab1 {
    margin: auto;
    background-color: #f8f9fa !important;
    width: 100%;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    opacity: 1;
    z-index: 100;
  }
  .conditional_nav {
    background-color: #f8f9fa;
    opacity: 1;
    z-index: 100;
  }
  .map_container {
    z-index: 98;
    height: 500px;
    cursor: auto !important;
  }
  .refreshMap {
    z-index: 600;
    position: absolute;
    top: 12px;
    left: 50px;
  }
  /* .card_render_data {
    padding: 0px 8px 0px 8px;
    display: flex;
  } */
  .hide{
        /* z-index: 31; */
        position: absolute !important;
        z-index: 400;
        /* background: red; */
        right: 10px !important;
        top: 70% !important;
       
  }
  .show{
    position: absolute !important;
    z-index: 400;
    /* background: red; */
    right: 10px !important;
    /* top: 70% !important; */
 
  }
 
  .card_data {
    padding: 0px 4px 0px 8px;
  }
  /* .home-page {
    overflow-x: hidden !important;
  } */
}
.hide-btn{
  display: none !important;
  width: 120px;
  margin-left: 16px;
}
@media (min-width: 739px) and (max-width: 948px) {
  .useremail{
    display: none;
  }
  .main-content-right {
    /* height: calc(100vh - 125px) !important; */
    height: 75vh !important;
    
  }
}
@media  screen and (max-width:1280px) {
  .reset {
    margin-left: 15px !important;
    font-size: 14px !important;
  }
 }
@media  screen and (max-width:600px) {
 .hide-btn{
   display: block !important;
 }
}
@media (min-width: 480px) and (max-width: 739px) {
  .right-nav {
    display: none;
  }
  .unit_tab {
    margin-left: -7px;
  }
  .project_tab {
    margin-left: -7px;
  }
  /* .search_nav_bar{
        margin-left: -7px;
    } */
  /* .conditional_icon{
        display: block;
        margin: 5px 0px 0px 6rem;
    } */
}
@media (min-width: 595px) and (max-width: 654px) {
  .search_nav_bar {
    margin: 0px -60px 0px 0px !important;
  }
}
@media (min-width: 481px) and (max-width: 594px) {
   .unit_tab {
     /* width: 750px;    */
     width: 33rem;
    margin-left: 5px;
  }
  .project_tab {
    width: 300%;  
    margin-left: 0px;
    margin-right: -6px;
  }
  .search_nav_bar {
    margin: 50px -60px 0px -42.813rem !important;
  }
  .search_nav_barinput{
    width: 20rem !important;
  }
  .conditional_icon {
    display: block;
    margin: 5px 0px 0px 10rem !important;  
  }
  .main-content{
    margin: 30px 0px 0px 0px
  }
  button.searchButton {
    margin-bottom: 10px;
    margin-left: 0px;
  }
}
@media (min-width: 594px) and (max-width: 739px) {
  .conditional_icon {
    display: block;
    margin: 5px 0px 0px 6rem !important;  
  }
}
@media screen and (max-width: 767px) {
  .map_container {
    width: 96vw !important;
  }
}
@media screen and (max-width: 1310px) {
  .extra {
    margin-left: 25px;
  }
}
@media screen and (max-width: 600px) {
  .main-content-right-box {
    padding: 0px !important;
  }
  .main-content-right {
    padding: 10px;
    margin-left: -8px !important;
  }
  .main-content-right-box1{
    width: 100% !important;
    margin:auto !important;
  }
  .main-content-right1{
    padding: 0px !important;
  }

}
.main-content-right-box1{
  margin: -8px 0px !important;
}

svg.free-draw{
  position: absolute !important;
  top: 0px;
}