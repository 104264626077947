body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f9fa;
}
html,body, #root, #login {
  height: 100%;
  overflow: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.error{
  color:red
}
.loading {
  position: fixed;
  background: #ffffffe3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1400;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;    
  flex-direction: column;
}


.leaflet-container {
  height: 400px;
  width: 90%;
  margin: 0 auto;
}

path.leaflet-line {
  stroke: #50622b;
  stroke-width: 2;
}

div.leaflet-edge {
  background-color: #95bc59;
  box-shadow: 0 0 0 2px white, 0 0 10px rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  cursor: move;
  outline: none;
  transition: background-color 0.25s;
}

div.leaflet-edge.disabled {
  pointer-events: none;
  background-color: #bbb;
}

path.leaflet-polygon {
  fill: #b4cd8a;
  stroke: #50622b;
  stroke-width: 2;
  fill-opacity: 0.75;
}

.map.mode-create {
  cursor: crosshair;
}
